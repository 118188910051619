


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { meetingsStore, teamsStore } from '@/store'
import {
  Contact,
  Freq,
  Meeting,
  MeetingsCreateRequest,
  MeetingsCreateRequestJSON,
  MeetingsMemberCreateParams,
  MeetingsUpdateCellRequest,
  MeetingsUpdateCellRequestJSON,
  MeetingsUpdateRequest,
  MeetingsUpdateRequestJSON,
} from '@tada-team/tdproto-ts'
import { meetingDialogLogger } from '@/loggers'
import { TdBtn } from 'td-ui'

@Component({
  components: {
    TdBtn,
  },
})
export default class MeetingDialogActions extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) readonly isShowMode!: boolean

  @Prop({
    type: Boolean,
    required: true,
  }) readonly disabled!: boolean

  @Prop({
    type: Boolean,
    required: true,
  }) readonly isEditDialog!: boolean

  @Prop({
    type: Boolean,
    required: true,
  }) readonly isEditingCell!: boolean

  @Prop({
    type: Boolean,
    required: true,
  }) readonly isOnline!: boolean

  @Prop({
    type: Number,
    required: true,
  }) readonly duration!: number

  @Prop({
    type: String,
    required: true,
  }) readonly startDate!: string

  @Prop({
    type: String,
    required: true,
  }) readonly endDate!: string

  @Prop({
    type: String,
    required: true,
  }) readonly description!: string

  @Prop({
    type: Array,
    required: true,
  }) readonly members!: Contact[]

  @Prop({
    type: Object,
  }) readonly freq?: Freq

  @Prop({
    type: Object,
  }) readonly updatedMeeting?: Meeting

  private maxDescriptionLength = 2000

  private loading = false

  private closeDialog (): void {
    meetingsStore.actions.closeMeetingDialog()
  }

  private cancel (): void {
    this.sendGoal(this.isEditDialog
      ? 'Отмена редактирования встречи — кнопка «Отменить»'
      : 'Отмена создания встречи — кнопка «Отменить»')
    this.closeDialog()
  }

  private get dateGoal () {
    const startDate = new Date(this.startDate)
    const endDate = new Date(this.endDate)
    let goal = ''
    if (
      endDate.getFullYear() < startDate.getFullYear() ||
      endDate.getMonth() < startDate.getMonth() ||
      endDate.getDate() < startDate.getDate()) {
      goal = '«Создать» с неверной датой конца'
    } else {
      goal = '«Создать» с неверным временем конца'
    }
    return goal
  }

  private get currentTeam () {
    return teamsStore.getters.currentTeam
  }

  private get descriptionAndTitle () {
    const splitIndex = this.description.indexOf('\n')
    const title = splitIndex >= 0 ? this.description.substr(0, splitIndex) : this.description
    const description = splitIndex >= 0 ? this.description.substr(splitIndex) : ''
    return {
      description,
      title,
    }
  }

  async saveMeeting (): Promise<void> {
    if (this.disabled || this.loading) return
    if (this.duration < 0) {
      this.$emit('durationError')
      this.sendGoal(this.dateGoal)
      return
    }

    if (!this.description) {
      this.sendGoal('«Создать» с ошибкой «Пустое название»')
    }

    if (this.description?.length > this.maxDescriptionLength) {
      this.sendGoal('«Создать» с ошибкой «Превыше лимит символов»')
    }
    if (!this.description || this.description.length > this.maxDescriptionLength) {
      this.$emit('descriptionError')
      return
    }

    this.loading = true
    const updateMeeting = meetingsStore.state.editingMeeting
    try {
      if (updateMeeting) {
        if (this.isEditingCell) await this.updateCell()
        else await this.update()
      } else {
        await this.create()
      }
      this.$emit('beforeSave')
    } catch {
      meetingDialogLogger.warn('Meeting dialog save failing')
    }
    this.loading = false
    this.closeDialog()
  }

  private async updateCell () {
    if (!this.updatedMeeting) return
    const request: MeetingsUpdateCellRequestJSON = {
      cell_start_new_date: this.startDate,
      cell_start_old_date: this.updatedMeeting.startAt,
      duration: this.duration,
      meeting_id: this.updatedMeeting.id,
      team_uuid: this.currentTeam.uid,
    }

    await meetingsStore.actions
      .updateMeetingCell({
        meetingJid: this.updatedMeeting.jid,
        request: MeetingsUpdateCellRequest.fromJSON(request),
      })

    this.sendGoal('Успешное редактирование — Сохранить «Только эту» повторяющуюся встречу')
  }

  private async update () {
    if (!this.updatedMeeting) return

    const { description, title } = this.descriptionAndTitle
    const updateMeetingId = this.updatedMeeting.jid
    const deletedMeetingMembers: string[] = []
    const addedMembers: MeetingsMemberCreateParams[] = []
    const meetingMembers = this.updatedMeeting.meetingMembers ?? []
    const me = teamsStore.getters.currentTeam.me

    // Finding removed members
    meetingMembers.forEach(m => {
      if (
        m.contact.jid === me.jid ||
        this.members.some(item => item.jid === m.contact.jid)
      ) return
      deletedMeetingMembers.push(m.contact.jid)
    })

    // Finding new members
    this.members.forEach(m => {
      if (!meetingMembers.some(member => member.contact.jid === m.jid)) {
        addedMembers.push(new MeetingsMemberCreateParams(m.jid, 'MEETING_MEMBER_STATUS_MEMBER'))
      }
    })

    const updateRequestJson: MeetingsUpdateRequestJSON = {
      duration: this.duration,
      start_at: new Date(this.startDate).toISOString(),
      is_outside: !this.isOnline,
      meeting_id: updateMeetingId,
      title,
      description: description ?? '',
      team_uuid: this.currentTeam.uid,
      remove_members: deletedMeetingMembers,
      add_members: addedMembers,
      freq: this.freq?.toJSON() ?? undefined,
    }
    await meetingsStore.actions.updateMeeting(MeetingsUpdateRequest.fromJSON(updateRequestJson))
    this.sendGoal(!this.freq
      ? 'Успешное редактирование — Сохранить неповторяющуюся встречу'
      : 'Успешное редактирование — Сохранить Все повторяющиеся встречи',
    )
  }

  private async create () {
    const { description, title } = this.descriptionAndTitle
    const meetingMembersCreateParams = this.members.map(m => new MeetingsMemberCreateParams(m.jid, 'MEETING_MEMBER_STATUS_MEMBER'))
    const createRequestJson: MeetingsCreateRequestJSON = {
      start_at: new Date(this.startDate).toISOString(),
      duration: this.duration,
      description,
      title,
      is_public: false,
      is_outside: !this.isOnline,
      members: meetingMembersCreateParams,
      team_uuid: this.currentTeam.uid,
      owner_contact_uuid: this.currentTeam.me.jid,
      freq: this.freq?.toJSON() ?? undefined,
    }
    await meetingsStore.actions.createMeeting(MeetingsCreateRequest.fromJSON(createRequestJson))
    this.sendGoal('Успешное создание встречи — кнопка «Создать»')
  }

  private sendGoal (goal: string) {
    window.goal('Calendar', { Calendar: goal })
  }
}

